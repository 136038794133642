import { ModalNames, useModal } from "@/app/_contexts/overlay";
import { AssetWithBalance, Status } from "@/app/_hooks/types";
import { useBalances } from "@/app/_hooks/useBalances";
import { useSubaccountList } from "@/app/_hooks/useSubaccount";
import { Button } from "@arkham/ui-components";
import BigNumber from "bignumber.js";
import React, { useMemo, useState } from "react";
import { MobileMoreButton } from "../ui/buttons/MobileMoreButton";
import { Column, Direction, Table } from "../ui/elements/table/Table";
import { AssetIcon } from "../ui/images/AssetIcon";
import { PrivacyNumber } from "../ui/number/PrivacyNumber";

export function AssetActions({ asset }: { asset: AssetWithBalance }) {
    const { openModal, closeModal } = useModal();
    const { sortedSubaccounts } = useSubaccountList();

    const oneSubaccount = sortedSubaccounts.length === 1;

    const hasBalance = !new BigNumber(asset.balance).eq(0);

    const openDepositModal = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        closeModal(ModalNames.WalletAssetInfo);
        openModal(ModalNames.Deposit, {
            asset,
        });
    };

    const openWithdrawalModal = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        closeModal(ModalNames.WalletAssetInfo);
        openModal(ModalNames.Withdrawal, {
            asset,
        });
    };

    const openTransferModal = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        closeModal(ModalNames.WalletAssetInfo);
        openModal(ModalNames.Transfer, {
            asset,
        });
    };

    return (
        <div className="flex gap-1.5">
            {(asset.status === Status.Listed || asset.status === Status.Staged) && (
                <Button color="green" variant="tertiary" size="sm" onClick={openDepositModal}>
                    Deposit
                </Button>
            )}
            {hasBalance && (asset.status === Status.Listed || asset.status === Status.Delisted) && (
                <Button color="grey" size="sm" variant="tertiary" onClick={openWithdrawalModal}>
                    Withdraw
                </Button>
            )}
            {!oneSubaccount && hasBalance && (
                <Button size="sm" color="blue" variant="tertiary" onClick={openTransferModal}>
                    Transfer
                </Button>
            )}
        </div>
    );
}

export function AssetsSection() {
    const { balances } = useBalances();

    const { openModal } = useModal();

    const [sort, setSort] = useState<{ col: "balance" | "usd" | "free"; direction: Direction }>({
        col: "usd",
        direction: Direction.Desc,
    });

    const sortedData = useMemo(() => {
        const multiplier = sort.direction === Direction.Asc ? 1 : -1;
        return [...balances].sort((a, b) => {
            let valueA: BigNumber;
            let valueB: BigNumber;

            switch (sort.col) {
                case "balance":
                    valueA = new BigNumber(a.balance);
                    valueB = new BigNumber(b.balance);
                    break;
                case "free":
                    valueA = new BigNumber(a.free);
                    valueB = new BigNumber(b.free);
                    break;
                case "usd":
                default:
                    valueA = new BigNumber(a.balanceUSDT);
                    valueB = new BigNumber(b.balanceUSDT);
                    break;
            }
            return multiplier * valueA.comparedTo(valueB);
        });
    }, [balances, sort]);

    const columns: Column[] = useMemo(
        () =>
            [
                { header: "Asset", template: "1fr md:2fr" },
                {
                    header: "Balance",
                    justifyContent: "justify-end",
                    onSort: (direction: Direction) => setSort({ col: "balance", direction }),
                    sorted: sort.col === "balance" ? sort.direction : false,
                    template: "md:6rem",
                },

                {
                    header: "Free Balance",
                    justifyContent: "justify-end",
                    onSort: (direction: Direction) => setSort({ col: "free", direction }),
                    sorted: sort.col === "free" ? sort.direction : false,
                    breakpoint: "md",
                    template: "md:6rem",
                },
                {
                    header: "USD",
                    justifyContent: "justify-end",
                    onSort: (direction: Direction) => setSort({ col: "usd", direction }),
                    sorted: sort.col === "usd" ? sort.direction : false,
                    template: "md:6rem",
                },
                {
                    header: <span className="hidden md:inline">Actions</span>,
                    template: "3rem md:1fr",
                    justifyContent: "justify-center md:justify-end",
                    key: "actions",
                },
            ] as Column[],
        [sort],
    );

    const AssetRow = ({ asset }: { asset: AssetWithBalance }) => (
        <React.Fragment key={asset.symbol}>
            <div className="flex items-center gap-1.5">
                <AssetIcon url={asset.imageUrl} width={16} height={16} />
                <span className="md:hidden">{asset.name}</span>
                <span className="hidden md:inline">{`${asset.name} (${asset.symbol})`}</span>
            </div>
            <div>
                <PrivacyNumber number={asset.balance} options={{ fractionDigits: 6 }} />
            </div>

            <div>
                <PrivacyNumber number={asset.free} options={{ fractionDigits: 6 }} />
            </div>

            <div>
                <PrivacyNumber
                    number={asset.balanceUSDT}
                    options={{
                        fractionDigits: 2,
                        style: "currency",
                        currency: "USD",
                    }}
                />
            </div>

            <div>
                <MobileMoreButton
                    onClick={(event) => {
                        event.preventDefault();
                        openModal(ModalNames.WalletAssetInfo, asset);
                    }}
                />
                <div className="hidden md:block">
                    <AssetActions asset={asset} />
                </div>
            </div>
        </React.Fragment>
    );

    return (
        <Table
            columns={columns}
            rowLink={(i) => (sortedData[i]?.featuredPair ? `/trade/${sortedData[i].featuredPair}` : undefined)}
            rowHeight="2.625rem"
        >
            {sortedData?.map((asset) => <AssetRow key={asset.symbol} asset={asset} />)}
        </Table>
    );
}
