"use client";

import { AirdropABI } from "@/app/_abis/Airdrop";
import { ModalNames, useModal } from "@/app/_contexts/overlay";
import { useApiQuery } from "@/app/_hooks/useApi";
import { env } from "@/app/_types/env";
import { Link } from "@arkham/i18n";
import { Button, Logo } from "@arkham/ui-components";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useTranslations } from "next-intl";
import { CgClose } from "react-icons/cg";
import { useAccount, useWaitForTransactionReceipt, useWriteContract } from "wagmi";
import { mainnet } from "wagmi/chains";

export function AirdropClaimModal() {
    const t = useTranslations("AirdropClaimModal");
    const { closeModal } = useModal();
    const { data: airdropClaim } = useApiQuery("/airdrop/claim");

    return (
        <div className="max-w-[800px] p-6">
            <div className="mb-4 flex items-center justify-between">
                <div className="text-xl font-bold">{t("airdropClaim")}</div>
                <CgClose className="cursor-pointer text-2xl" onClick={() => closeModal(ModalNames.AirdropClaim)} />
            </div>

            {airdropClaim?.passedKYC ? <AirdropClaimContent /> : <AirdropNoKyc />}
        </div>
    );
}

function AirdropNoKyc() {
    const t = useTranslations("AirdropClaimModal");

    return (
        <div className="flex flex-col items-center justify-center space-y-4 p-4 text-center">
            <p>{t("airdropNoKyc")}</p>
            <Link
                href="https://auth.arkm.com/airdrop-kyc"
                className="text-xl text-blue-800"
                target="_blank"
                rel="noopener noreferrer"
            >
                {t("airdropNoKycLink")}
            </Link>
        </div>
    );
}

function AirdropClaimContent() {
    const { isConnected } = useAccount();
    const { data: airdropClaim } = useApiQuery("/airdrop/claim");
    const contractAddress = env.NEXT_PUBLIC_SEASON_1_AIRDROP_ADDRESS;
    const { writeContract, isPending, error, data: hash } = useWriteContract();
    const t = useTranslations("AirdropClaimModal");
    const {
        isLoading: isTxPending,
        isSuccess: isTxConfirmed,
        error: waitError,
    } = useWaitForTransactionReceipt({
        hash: hash,
        chainId: mainnet.id,
        confirmations: 1,
    });

    const claimAmount = BigInt(airdropClaim?.wei ?? 0);

    const handleClaim = async () => {
        if (!airdropClaim) {
            console.error("Airdrop claim data not available.");
            return;
        }

        try {
            writeContract({
                abi: AirdropABI,
                address: contractAddress,
                functionName: "claim",
                args: [
                    airdropClaim.address as `0x${string}`,
                    claimAmount,
                    airdropClaim.proof as readonly `0x${string}`[],
                ],
            });
        } catch (err) {
            console.error("Error executing claim:", err);
        }
    };

    return isConnected ? (
        <>
            <div className="grid grid-cols-[auto_1fr] gap-x-2 gap-y-1 text-sm">
                <div className="">{t("address")}:</div>
                <div>
                    {airdropClaim?.address && (
                        <Link
                            href={`https://intel.arkm.com/explorer/address/${airdropClaim.address}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="hover:underline"
                        >
                            {airdropClaim.address}
                        </Link>
                    )}
                </div>
                <div className="">{t("amount")}:</div>
                <div>
                    {airdropClaim?.amount ?? 0} {"ARKM"}
                </div>
            </div>

            {hash ? (
                <div className="mt-2">
                    <div className="flex flex-col items-center text-sm">
                        {isTxConfirmed ? (
                            <p className="text-green-800">{t("airdropClaimSuccess")}</p>
                        ) : (
                            isTxPending && (
                                <>
                                    <Logo className="h-8 w-8" of="loadingIcon" />
                                    <p className="mt-1 text-sm text-yellow-900">
                                        {t("waitingForTransactionConfirmation")}
                                    </p>
                                </>
                            )
                        )}
                        <Link
                            href={`https://etherscan.io/tx/${hash}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="mt-1 text-sm text-blue-800 hover:underline"
                        >
                            {t("viewTransaction")}
                        </Link>
                    </div>
                </div>
            ) : (
                <div className="mt-4 flex justify-center">
                    <Button
                        color="green"
                        variant="secondary-alpha"
                        className="text-xs"
                        onClick={handleClaim}
                        disabled={isPending}
                        isLoading={isPending}
                    >
                        {isPending ? t("claiming") : t("claimAirdrop")}
                    </Button>
                </div>
            )}

            {error || waitError ? (
                <div className="mt-4 text-sm text-red-800">
                    <span>{t("encounteredAnError")}: </span>
                    {error && error.message}
                    {waitError && waitError.message}
                </div>
            ) : null}
        </>
    ) : (
        <>
            <div className="mt-4 flex justify-center">
                <ConnectButton />
            </div>
            <p className="mt-4 text-sm">{t("pleaseConnectYourWalletToClaimTheAirdrop")}</p>
        </>
    );
}
