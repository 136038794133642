"use client";

import { UserInfoResponse } from "@/app/_hooks/types";
import { useBalanceSubscription } from "@/app/_hooks/useBalances";
import { useMarginSubscription } from "@/app/_hooks/useMargin";
import { useOrdersSubscription } from "@/app/_hooks/useOrders";
import { usePositionsSubscription } from "@/app/_hooks/usePositions";
import { useQuery } from "@tanstack/react-query";
import { createContext, useContext } from "react";
import { env } from "../_types/env";

type AuthState = {
    user: UserInfoResponse | null;
};

export async function fetchUserData() {
    const res = await fetch(`${env.NEXT_PUBLIC_API_URL}/user`, {
        credentials: "include",
    });

    if (!res.ok) {
        return null;
        throw new Error("Failed to fetch user data");
    }

    const user: UserInfoResponse = await res.json();
    const sanctionedHeader = res.headers.get("X-User-Sanctioned-Ip");

    return {
        user: user,
        sanctionedIp: sanctionedHeader === "true",
    };
}

const AuthContext = createContext({} as AuthState);

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error("useAuth must be used within a AuthProvider");
    }
    return context;
};

interface UseBalanceSubscriptionProps {
    user: UserInfoResponse | null;
}

//this feels bad, need to refactor websocket/state management a bit
const SubscriptionsManager = ({ userId }: { userId: number }) => {
    useBalanceSubscription({ userId });
    useMarginSubscription({ userId });
    usePositionsSubscription({ userId });
    useOrdersSubscription({ userId });
    return null;
};

export const AuthProvider = ({
    children,
    serverUser,
}: {
    children: React.ReactNode;
    serverUser: UserInfoResponse | null;
}) => {
    const { data } = useQuery({
        queryKey: ["/user"],
        queryFn: fetchUserData,
        initialData: { user: serverUser, sanctionedIp: false },
    });
    const user = data?.user ?? null;
    return (
        <AuthContext.Provider value={{ user }}>
            {user && <SubscriptionsManager userId={user.id} />}
            {children}
        </AuthContext.Provider>
    );
};
