"use client";

import {
    Balance,
    CancelAllRequest,
    CancelAllResponse,
    CancelAllTriggerOrdersRequest,
    CancelAllTriggerOrdersResponse,
    CancelOrderRequest,
    CancelOrderResponse,
    CancelTriggerOrderRequest,
    CancelTriggerOrderResponse,
    Candle,
    CandleDuration,
    CreateOrderRequest,
    CreateOrderResponse,
    CreateTriggerOrderRequest,
    CreateTriggerOrderResponse,
    L2Update,
    Margin,
    Order,
    OrderBook,
    Position,
    Ticker,
    Trade,
    TriggerOrder,
} from "@/app/_hooks/types";
import { env } from "@/app/_types/env";
import { components } from "@/app/_types/models.gen";
import { randomBytes } from "crypto";
import { createContext, useContext, useEffect, useRef, useState } from "react";

export const ConnectionStatus = {
    Connecting: "connecting",
    Disconnected: "disconnected",
    Unstable: "unstable",
    Stable: "stable",
} as const;
export type ConnectionStatus = (typeof ConnectionStatus)[keyof typeof ConnectionStatus];

export const Channel = {
    Candles: "candles",
    L2Updates: "l2_updates",
    Trades: "trades",
    Ticker: "ticker",
    Balances: "balances",
    Positions: "positions",
    OrderStatuses: "order_statuses",
    Margin: "margin",
    Errors: "errors",
    Pong: "pong",
    Confirmations: "confirmations",
    TriggerOrders: "trigger_orders",
    OrdersNew: "orders/new",
    OrdersCancel: "orders/cancel",
    OrdersCancelAll: "orders/cancel/all",
    TriggerOrdersNew: "trigger_orders/new",
    TriggerOrdersCancel: "trigger_orders/cancel",
    TriggerOrdersCancelAll: "trigger_orders/cancel/all",
} as const;
export type ChannelType = (typeof Channel)[keyof typeof Channel];

type DataChannel = Exclude<ChannelType, typeof Channel.Errors | typeof Channel.Pong>;

export const DataType = {
    Update: "update",
    Snapshot: "snapshot",
} as const;
export type DataType = (typeof DataType)[keyof typeof DataType];

function getWSCandleDurationFromResolutionString(resolution: string): string {
    switch (resolution) {
        case "1m":
            return "60000000";
        case "5m":
            return "300000000";
        case "15m":
            return "900000000";
        case "30m":
            return "1800000000";
        case "1h":
            return "3600000000";
        case "6h":
            return "21600000000";
        case "24h":
            return "86400000000";
        default:
            throw new Error(`Unexpected Resolution String ${resolution}`);
    }
}

type WebsocketRequest = components["schemas"]["WebsocketRequest"];
type WebsocketResponse = components["schemas"]["WebsocketResponse"];
type WebsocketMethod = components["schemas"]["WebsocketMethod"];

type Data<C extends DataChannel> = Extract<
    Exclude<WebsocketResponse, { channel: "errors" } | { channel: "pong" } | { channel: "confirmations" }>,
    { channel: `${C}` }
>["data"];

type SubscribeArgs<C extends DataChannel> = (WebsocketRequest & { method: "subscribe" })["args"] & { channel: `${C}` };
type Handler<C extends DataChannel> = (data: Data<C>) => void;
type ExecuteArgs<C extends DataChannel> = (WebsocketRequest & { method: "execute" })["args"] & { channel: `${C}` };

export class WebsocketClient {
    private url: string;
    private ws: WebSocket;
    private subscriptions: Map<string, Set<Handler<DataChannel>>>;
    private requests: Map<string, [Handler<DataChannel>, (reason: Error) => void]>;
    private connectListeners: Set<() => void>;
    private pingInterval: NodeJS.Timeout | null = null;
    private pingTimestamps: Map<string, number> = new Map();
    private latencies: number[] = [];
    private _connectionStatus: ConnectionStatus = "connecting";
    private connectionStatusListeners: Set<(status: ConnectionStatus) => void> = new Set();

    constructor(url: string) {
        this.subscriptions = new Map();
        this.requests = new Map();
        this.connectListeners = new Set();
        this.url = url;
        this.ws = this.connect();
    }

    get connectionStatus(): ConnectionStatus {
        return this._connectionStatus;
    }

    private setConnectionStatus(status: ConnectionStatus) {
        if (this._connectionStatus !== status) {
            this._connectionStatus = status;
            this.connectionStatusListeners.forEach((listener) => listener(status));
        }
    }

    public onConnectionStatusChange(listener: (status: ConnectionStatus) => void): () => void {
        this.connectionStatusListeners.add(listener);
        // Immediately call with current status
        listener(this._connectionStatus);

        return () => {
            this.connectionStatusListeners.delete(listener);
        };
    }

    public getAverageLatency(): number | null {
        if (this.latencies.length === 0) return null;
        return this.latencies.reduce((sum, latency) => sum + latency, 0) / this.latencies.length;
    }
    public disconnect() {
        if (this.pingInterval) {
            clearInterval(this.pingInterval);
            this.pingInterval = null;
        }
        this.ws!.close();
    }
    private connect(): WebSocket {
        const ws = new WebSocket(this.url);
        ws.onmessage = this.onMessage.bind(this);
        ws.onclose = this.onClose.bind(this);
        ws.onopen = this.onOpen.bind(this);
        return ws;
    }
    private getSubscriptionKey(args: (WebsocketRequest & { method: "subscribe" })["args"]): string {
        switch (args.channel) {
            case Channel.Balances:
            case Channel.OrderStatuses:
            case Channel.Positions:
            case Channel.Margin:
            case Channel.TriggerOrders:
                return `${args.channel}:${args.params!.subaccountId}`;
            case Channel.Candles:
                return `${args.channel}:${args.params!.symbol}:${getWSCandleDurationFromResolutionString(args.params!.duration!)}`; // we always pass duration
            case Channel.L2Updates:
                return `${args.channel}:${args.params!.symbol}:${args.params!.group!}`; // we always pass group
            case Channel.Ticker:
                return `${args.channel}:${args.params!.symbol}`;
            case Channel.Trades:
                return `${args.channel}:${args.params!.symbol}`;
            default:
                throw new Error(`Unknown channel: ${args.channel}`);
        }
    }
    private send(request: WebsocketRequest) {
        if (this.ws?.readyState === WebSocket.OPEN) {
            this.ws.send(JSON.stringify(request));
        }
    }
    private subscribe<C extends DataChannel>(args: SubscribeArgs<C>, handler: Handler<NoInfer<C>>) {
        const sendSubscribe = () => this.send({ method: "subscribe", args });
        const key = this.getSubscriptionKey(args);
        if (!this.subscriptions.has(key)) {
            this.subscriptions.set(key, new Set());
            this.connectListeners.add(sendSubscribe);
            sendSubscribe();
        }
        this.subscriptions.get(key)!.add(handler as Handler<DataChannel>);
        return () => {
            this.connectListeners.delete(sendSubscribe);
            this.subscriptions.get(key)!.delete(handler as Handler<DataChannel>);
            if (this.subscriptions.get(key)!.size === 0) {
                this.subscriptions.delete(key);
                this.send({ method: "unsubscribe", args });
            }
        };
    }
    private request<C extends DataChannel>(args: ExecuteArgs<C>): Promise<Data<C>> {
        const confirmationId = randomBytes(16).toString("hex");
        return new Promise((resolve, reject) => {
            this.requests.set(confirmationId, [resolve as Handler<DataChannel>, reject]);
            this.send({
                method: "execute",
                confirmationId,
                args: args,
            });
        });
    }
    private onOpen() {
        this.connectListeners.forEach((listener) => listener());
        // Immediately show stable connection when socket opens
        this.setConnectionStatus("stable");

        // Set up ping interval
        this.startPingInterval();
    }

    private startPingInterval() {
        // Clear any existing interval
        if (this.pingInterval) {
            clearInterval(this.pingInterval);
        }

        // Send a ping every second
        this.pingInterval = setInterval(() => {
            if (this.ws?.readyState === WebSocket.OPEN) {
                const timestamp = performance.now();
                this.pingTimestamps.set("ping", timestamp);
                this.send({ method: "ping" });
            }
        }, 1000);
    }

    private updateConnectionStatus() {
        if (this.ws.readyState !== WebSocket.OPEN) {
            this.setConnectionStatus("disconnected");
            return;
        }

        // If we have less than 3 measurements, maintain current status unless it's Disconnected
        // This prevents early flickering between states
        if (this.latencies.length < 3) {
            // If we're disconnected, at least show connecting
            if (this._connectionStatus === "disconnected") {
                this.setConnectionStatus("connecting");
            }
            return;
        }

        const avgLatency = this.getAverageLatency()!;

        // Only consider connection unstable once we have enough data AND it's consistently bad
        // Consider connection unstable if average latency is over 300ms
        if (avgLatency > 300) {
            this.setConnectionStatus("unstable");
            return;
        }

        // Check for significant variance in latencies
        const latencyVariance = Math.max(...this.latencies) - Math.min(...this.latencies);
        if (latencyVariance > 200) {
            this.setConnectionStatus("unstable");
            return;
        }

        this.setConnectionStatus("stable");
    }
    private onMessage(event: MessageEvent) {
        const resp = JSON.parse(event.data) as WebsocketResponse;
        if (resp.channel === Channel.Errors) {
            console.error(resp.message);
            this.requests.get(resp.confirmationId!)?.[1](new Error(resp.message));
            this.requests.delete(resp.confirmationId!);
            return;
        } else if (resp.channel === Channel.Pong) {
            if (this.pingTimestamps.size > 0) {
                const oldestPingId = Array.from(this.pingTimestamps.keys())[0];
                const startTime = this.pingTimestamps.get(oldestPingId)!;
                const latency = performance.now() - startTime;

                this.latencies.push(latency);
                if (this.latencies.length > 5) {
                    this.latencies.shift();
                }

                this.pingTimestamps.delete(oldestPingId);
                this.updateConnectionStatus();
            }
            return;
        } else if (resp.channel === Channel.Confirmations) {
            return;
        } else if (
            resp.channel === Channel.OrdersNew ||
            resp.channel === Channel.OrdersCancel ||
            resp.channel === Channel.OrdersCancelAll ||
            resp.channel === Channel.TriggerOrdersNew ||
            resp.channel === Channel.TriggerOrdersCancel ||
            resp.channel === Channel.TriggerOrdersCancelAll
        ) {
            this.requests.get(resp.confirmationId!)?.[0](resp.data);
            this.requests.delete(resp.confirmationId!);
            return;
        }
        const { data, channel, type } = resp;

        let key: string;
        switch (channel) {
            case Channel.Balances:
            case Channel.OrderStatuses:
            case Channel.TriggerOrders:
            case Channel.Positions:
                if (type === DataType.Snapshot) {
                    if (data.length === 0) return;
                    key = `${channel}:${data[0].subaccountId}`;
                } else {
                    key = `${channel}:${data.subaccountId}`;
                }
                break;
            case Channel.Margin:
                key = `${channel}:${data.subaccountId}`;
                break;
            case Channel.Candles:
                key = `${channel}:${data.symbol}:${data.duration}`;
                break;
            case Channel.Ticker:
                key = `${channel}:${data.symbol}`;
                break;
            case Channel.L2Updates:
                key = `${channel}:${data.symbol}:${data.group}`;
                break;
            case Channel.Trades:
                if (type === DataType.Snapshot) {
                    if (data.length === 0) return;
                    key = `${channel}:${data[0].symbol}`;
                } else {
                    key = `${channel}:${data.symbol}`;
                }
                break;
            default:
                console.error(`Unknown channel: ${channel}`);
                return;
        }
        this.subscriptions.get(key)?.forEach((handler) => {
            try {
                handler(data);
            } catch (e) {
                console.error(e);
            }
        });
    }
    private onClose() {
        this.setConnectionStatus("disconnected");

        // Clear ping interval
        if (this.pingInterval) {
            clearInterval(this.pingInterval);
            this.pingInterval = null;
        }

        // Clear latency data
        this.latencies = [];
        this.pingTimestamps.clear();

        // Attempt to reconnect
        setTimeout(() => {
            this.ws = this.connect();
        }, 1000);
    }
    public candles(symbol: string, duration: CandleDuration, handler: (data: Candle) => void): () => void {
        return this.subscribe({ channel: Channel.Candles, params: { symbol, duration } }, handler);
    }
    public ticker(symbol: string, snapshot: boolean, handler: (data: Ticker) => void): () => void {
        return this.subscribe({ channel: Channel.Ticker, params: { symbol, snapshot } }, handler);
    }
    public l2updates(
        symbol: string,
        group: string,
        snapshot: boolean,
        handler: (data: L2Update | OrderBook) => void,
    ): () => void {
        return this.subscribe({ channel: Channel.L2Updates, params: { symbol, group, snapshot } }, handler);
    }
    public trades(symbol: string, snapshot: boolean, handler: (data: Trade | Trade[]) => void): () => void {
        return this.subscribe({ channel: Channel.Trades, params: { symbol, snapshot } }, handler);
    }
    public balances(subaccountId: number, snapshot: boolean, handler: (data: Balance | Balance[]) => void): () => void {
        return this.subscribe(
            { channel: Channel.Balances, params: { snapshot, subaccountId, snapshotInterval: 30 } },
            handler,
        );
    }
    public positions(
        subaccountId: number,
        snapshot: boolean,
        handler: (data: Position | Position[]) => void,
    ): () => void {
        return this.subscribe(
            { channel: Channel.Positions, params: { snapshot, subaccountId, snapshotInterval: 1 } },
            handler,
        );
    }
    public orderStatuses(subaccountId: number, handler: (data: Order | Order[]) => void): () => void {
        return this.subscribe({ channel: Channel.OrderStatuses, params: { subaccountId, snapshot: true } }, handler);
    }
    public margin(subaccountId: number, handler: (data: Margin) => void): () => void {
        return this.subscribe(
            { channel: Channel.Margin, params: { subaccountId, snapshot: true, snapshotInterval: 1 } },
            handler,
        );
    }
    public triggerOrders(subaccountId: number, handler: (data: TriggerOrder | TriggerOrder[]) => void): () => void {
        return this.subscribe({ channel: Channel.TriggerOrders, params: { subaccountId, snapshot: true } }, handler);
    }
    public createOrder(req: CreateOrderRequest): Promise<CreateOrderResponse> {
        return this.request({ channel: Channel.OrdersNew, params: req });
    }
    public cancelOrder(req: CancelOrderRequest): Promise<CancelOrderResponse> {
        return this.request({ channel: Channel.OrdersCancel, params: req });
    }
    public cancelAll(req: CancelAllRequest): Promise<CancelAllResponse> {
        return this.request({ channel: Channel.OrdersCancelAll, params: req });
    }
    public createTriggerOrder(req: CreateTriggerOrderRequest): Promise<CreateTriggerOrderResponse> {
        return this.request({ channel: Channel.TriggerOrdersNew, params: req });
    }
    public cancelTriggerOrder(req: CancelTriggerOrderRequest): Promise<CancelTriggerOrderResponse> {
        return this.request({ channel: Channel.TriggerOrdersCancel, params: req });
    }
    public cancelAllTriggerOrders(req: CancelAllTriggerOrdersRequest): Promise<CancelAllTriggerOrdersResponse> {
        return this.request({ channel: Channel.TriggerOrdersCancelAll, params: req });
    }
}

interface WebsocketContextValue {
    client: WebsocketClient | null;
    isConnected: boolean;
    connectionStatus: ConnectionStatus;
    latency: number | null;
}

const WebsocketContext = createContext<WebsocketContextValue | null>(null);

export const WebsocketProvider = ({ children }: { children: React.ReactNode }) => {
    const websocketURL = env.NEXT_PUBLIC_WEBSOCKET_URL;
    const wscRef = useRef<WebsocketClient | null>(null);
    const [isConnected, setIsConnected] = useState(false);
    const [client, setClient] = useState<WebsocketClient | null>(null);
    const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>("connecting");
    const [latency, setLatency] = useState<number | null>(null);

    useEffect(() => {
        if (!wscRef.current) {
            wscRef.current = new WebsocketClient(websocketURL);
            setClient(wscRef.current);

            // Initial status is based on WebSocket readyState
            const initialConnected = wscRef.current.connectionStatus !== "disconnected";
            setIsConnected(initialConnected);
            setConnectionStatus(wscRef.current.connectionStatus);

            // Set up listener for connection status changes
            const statusUnsubscribe = wscRef.current.onConnectionStatusChange((status) => {
                setConnectionStatus(status);
                setIsConnected(status !== "disconnected");
            });

            // Update latency every second
            const latencyInterval = setInterval(() => {
                if (wscRef.current) {
                    setLatency(wscRef.current.getAverageLatency());
                }
            }, 1000);

            return () => {
                statusUnsubscribe();
                clearInterval(latencyInterval);
                if (wscRef.current) {
                    wscRef.current.disconnect();
                    wscRef.current = null;
                    setClient(null);
                    setIsConnected(false);
                    setConnectionStatus("disconnected");
                    setLatency(null);
                }
            };
        }
    }, [websocketURL]);

    return (
        <WebsocketContext.Provider
            value={{
                client,
                isConnected,
                connectionStatus,
                latency,
            }}
        >
            {children}
        </WebsocketContext.Provider>
    );
};

export const useWebsocketClient = () => {
    const client = useContext(WebsocketContext);
    if (!client) {
        throw new Error("useWebsocketClient must be used within a WebsocketProvider");
    }
    return client;
};
